/* You can add global styles to this file, and also import other style files */
@import "../src/styles/certification-form.css";
@import "../src/styles/admin.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --siteblue: #163F92;
  --black: #000;
  --white: #fff;
  --grey: rgba(22, 63, 146, 0.19);
  --border-color: rgba(0, 0, 0, 0.32);
  --red: rgba(244, 18, 18, 1);
}

html,
body {
  height: 100%;
}

body, h1, h2, button, div, table, th, td {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
}

mat-hint {
  color: grey;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--white) !important;
  border: 1px solid var(--border-color);
}

.mat-form-field-underline {
  display: none !important;
}

.mdc-text-field--filled {
  border-radius: 5px !important;
}

.mat-mdc-raised-button.mat-blue {
  background-color: var(--siteblue) !important;
  color: var(--white) !important;
}

.mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 10px !important;
}

.mat-mdc-raised-button:disabled {
  background-color: #C4C4C4 !important
}

.mat-step-header {
  background-color: transparent !important;
}

.mat-step-header:hover {
  background-color: transparent !important;
}

.spinner {
  width: 23px !important;
  height: 40px !important;
  margin-right: 10px;
}

/* //Scroll bar design
// / width / */
::-webkit-scrollbar {
  width: 10px;
}

/* // / Track / */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 6px;
}

/* // / Handle / */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 10px;
}

/* // / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
  background: #e1e1e1;
}
/* //Scroll bar design ends */


@media (max-width: 767px) {
  .mat-select-panel-open {
    overflow: hidden !important;
  }
}

/* .mat-step-header.mat-step-header-active + .mat-stepper-horizontal-line {
  background-color: blue !important;
}

.mat-step-header.mat-step-header-active + .mat-stepper-horizontal-line:before {
  background-color: green !important;
} */


/* .pass-field .mat-mdc-text-field-wrapper {
  padding-left: 8px !important;
}

.pass-field .mat-mdc-form-field-infix {
  height: 10px;
  padding-top: 8px !important;
} */

/* .mat-mdc-text-field-wrapper {
  border-radius: 20px !important;
  border: none;
  background-color: var(--white) !important;
} */

.owl-theme .owl-nav [class*=owl-]{
  background: transparent !important;
}

.owl-prev, .owl-next {
  position: absolute;
  top: 39%;
}

.owl-prev {
  left: -20px;
}

.owl-next {
  right: -20px;
  display: flex !important;
  justify-content: flex-end;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
  background: var(--siteblue) !important;
}

.owl-theme .owl-nav .disabled {
  opacity: 0 !important;
}

.eligilibility-carousel .owl-dots, .feeStructure-carousel .owl-dots{
  position: absolute;
  bottom: -14%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feeStructure-carousel .owl-dots{
  bottom: -20% !important;
}

.eligilibility-carousel .owl-theme .owl-dots .owl-dot.active span, .eligilibility-carousel .owl-theme .owl-dots .owl-dot:hover span{
  background: #ffffff !important;
}

.eligilibility-carousel .owl-theme .owl-dots .owl-dot span{
  background: #A9A9AA;
}

.eligilibility-carousel .owl-nav .owl-prev i, .eligilibility-carousel .owl-nav .owl-next i{
  font-size: 60px;
}

.eligilibility-carousel .owl-carousel .owl-item img{
  filter: brightness(0.9);
}

.testimonial-carousel .owl-prev{
  left: -50px;
}

.testimonial-carousel .owl-next{
  right: -50px;
}

.launch-event-banner-box-shadow {
  box-shadow: 0px 30px 120px 0px rgba(39, 39, 39, 0.2);
}
