/* .certification-form-block{ padding: 5% 10% 0 10%; } */

.certification-form-block .mat-step-icon {
  height: 40px;
  width: 40px;
  /* background-color: var(--grey); */
  /* background-color: #FFCDD2; */
  /* color: var(--black); */
}

.certification-form-block .mat-step-icon-selected {
  background-color: var(--siteblue);
  color: var(--white);
}

.certification-form-block .mat-step-text-label {
  /* color: var(--siteblue); */
  font-size: 18px;
}

.certification-form-block .mat-stepper-horizontal-line {
  border-radius: 20px;
  margin: -30px -50px 0;
  border-top-width: 15px;
  border-top-color: var(--grey);
}

.certification-form-block .mat-horizontal-content-container {
  padding: 0 40px 24px 24px;
}

.certification-form-block sup {
  color: var(--red);
}

.next-button {
  border: 2px solid var(--grey) !important;
  color: var(--siteblue) !important;
}

@media only screen and (min-width:768px) {
  .certification-form-block .mat-step-header {
    flex-direction: column;
    justify-content: space-between;
    width: 19%;
  }
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: #163F92 !important;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #163F92 !important;
}

.mat-slide-toggle-bar {
	height: 18px !important;
  border-radius: 10px !important;
}

.mat-slide-toggle-thumb {
	margin-top: 5px;
	height: 14px !important;
  width: 14px !important;
	margin-left: 2px;
}

sup {
  color: red;
  font-size: 17px;
}

.pointer {
  cursor: pointer;
}

.error-container {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f44336; /* Customize the color */
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
}

.error-container span {
  flex-grow: 1;
}

.delete-icon {
  color: red;
  cursor: pointer;
}

.mat-sky {
  background-color: rgb(210, 210, 210) !important;
}

.mat-mdc-select-panel {
  box-shadow: 0px 20px 40px -20px rgba(60, 69, 99, 0.4) !important;
  border-radius: 8px !important;
  border: 1px solid rgb(221, 221, 221);
}

.business-type-select, .turnover-select {
  max-height: 306px !important;
}

.file-upload__native-selection {
  display: none;
}

.delete-button {
  background: #AC2727 !important;
  color: white !important;
}

.mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false]{
  background-color: transparent !important;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 9999; /* Ensure the spinner is above other content */
}

.mat-mdc-progress-spinner {
  overflow: visible !important;
}

.placeholder-text {
  position: absolute;
  right: 0;
  color: black;
}

.error-step {
  color: red !important;
}

.error-step .mat-step-header {
  background-color: #FFCDD2 !important; /* Light red */
}

.no-data-row {
  height: 150px;
  align-items: center;
  font-size: 20px;
  color: black;
  opacity: 0.8;
}

.file-name {
  font-size: 17px;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 280px;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking-text {
  animation: blink 1s infinite;
}

.mat-mdc-optgroup-label {
  font-weight: bold;
}