.dashboard-table table{
  width: 100%;
  border-collapse: separate !important;
  border-radius: 8px;
}

.dashboard-table table tr:last-child td /*To remove the last border*/ {
  border-bottom: 0 solid;
}

.dashboard-table td.mat-cell:last-of-type {
  border-right: none !important;
}

.dashboard-table tr:nth-child(even) {
  background-color: #f7f6f6;
}

.dashboard-table table tr th{
  background-color: #d9d9d9 !important;
}

.dashboard-table .mat-mdc-table{
  border: 1px solid rgba(0, 0, 0, 0.18) !important;
  background-color:transparent!important;
  font-family: 'DM Sans', sans-serif !important;
}

.dashboard-table .mdc-data-table__cell{
  height: 74px;
}

.dashboard-table th.mat-mdc-header-cell {
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #3C4563 !important;
}

.mat-column-actions {
  padding-left: 6px !important;
  padding-right: 0px !important;
}

.mat-column-embName {
  overflow-wrap: break-word;
  word-break: break-word;
}

.mat-mdc-cell:last-child {
  border-right: 0 !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label{
  color: rgba(35, 112, 228, 1) !important;
  font-weight: 900 !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline{
  border-color: rgba(35, 112, 228, 1) !important;
}

.mat-mdc-tab-labels{
  border-bottom: 1px solid rgba(238, 237, 239, 1) !important;
}

.embname{
  text-decoration: underline;
  cursor: pointer;
}

.status{
  text-decoration: underline;
  cursor: pointer;
}

.mdc-text-field--disabled{
  background-color: #efefef;
  border-radius: 10px !important;
}

.mdc-text-field--disabled input, .mat-mdc-select-disabled, .mdc-text-field--disabled textarea, .mdc-radio--disabled{
  color: #000 !important;
}

.border-certify-form{
  border: 2px solid rgba(0, 0, 0, 0.18) !important;
  border-radius: 8px;
}

.status-btn{
  background-color: #189216 !important;
  color: #fff !important;
  border-radius: 20px !important;
}

.reject-btn{
  background-color: #F41212 !important;
  color: #fff !important;
  border-radius: 20px !important;
}

.back-btn{
  border-radius: 20px !important;
  background-color: rgb(22 63 146) !important;
  color: #fff !important;
}

.mat-expansion-indicator svg{
  fill: rgb(22 63 146) !important;
  display: unset !important;
}

.mat-expansion-indicator::after{
  display: none !important;
}

.mat-step-header:hover{
  background-color: transparent !important;
}

.mat-step-header.cdk-program-focused {
    background-color: transparent !important;
}

.upload-certificate-btn{
  background-color: #163F92 !important;
  color: #fff !important;
}

.download-icon {
  color: rgb(46, 46, 46);
  width: 25px;
}